import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ReviewTag } from '@models';
import { IonList, IonListHeader, IonLabel, IonItem } from "@ionic/angular/standalone";

@Component({
    selector: 'tag-list',
    standalone: true,
    imports: [CommonModule, IonList, IonListHeader, IonLabel, IonItem],
    template: `
    <ion-list lines="full" mode="ios" *ngIf="tags?.length">
      <ion-list-header>
        <ion-label>Most Used Adjective from User Reviews</ion-label>
      </ion-list-header>
      <ion-item>
        <ion-label class="keywords ion-text-wrap">
          <div
            class="word"
            [style.fontSize.em]="tag.Weight > 0.5 ? tag.Weight : 0.5"
            *ngFor="let tag of tags"
          >
            {{ tag.Tag }}
          </div>
        </ion-label>
      </ion-item>
    </ion-list>
  `,
    styles: [
        `
      ion-label.keywords {
        font-size: 35px !important;
        display: flex !important;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;
      }
      .word {
        color: #e6594e;
        background: #fbeeee;
        border-radius: 5px;
        padding: 5px 10px;
      }
    `,
    ],
})
export class TagListComponent implements OnInit {
    @Input() tags: ReviewTag[] = [];
    constructor() { }

    ngOnInit() { }
}
