export enum ProductAttributeToDisplay {
  Manufacturer = 'Manufacturer',
  Origin = 'Origin',
  Strength = 'Strength',
  Wrapper = 'Wrapper',
  WrapperColor = 'Wrapper Color',
  Binder = 'Binder',
  Filler = 'Filler',
  Length = 'Length',
  MasterLine = 'Master Line',
  RingGauge = 'Ring Gauge',
  Section = 'Section',
  Shape = 'Shape',
  SinglePackaging = 'Single Packaging',
  RollingType = 'Rolling Type',
}

export enum ProductAttributeStrengths {
  Mild = 'Mild',
  Medium = 'Medium',
  Full = 'Full',
}

export enum ProductAttribute {
  Manufacturers = 1818,
  Strengths = 1810,
  Origins = 1809,
  Wrappers = 1812,
  Binders = 1813,
  Fillers = 1814,
  RollingTypes = 1815,
  WrapperColors = 1811,
  CARatings = 1903,
  CIRatings = 1905,
}
