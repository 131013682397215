import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Platform, IonIcon } from '@ionic/angular/standalone';
import { IAux, INeptunePrices } from '@shared/models/product.model';
import { PriceRangeStandalonePipe } from '@shared/pipes/price-range2.pipe';
import { AnalyticsService } from '@shared/services/analytics.service';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';

@Component({
    selector: 'cigar-neptune-price',
    standalone: true,
    imports: [CommonModule, PriceRangeStandalonePipe, IonIcon],
    template: `
    <div
      (click)="open()"
      *ngIf="display"
      class="wrapper"
      [style.width.px]="width"
      [style.height.px]="height"
      [style.fontSize.px]="fontSize"
      [style.lineHeight.px]="fontSize"
    >
      <div class="grid">
        <div class="price">
          <span
            class="per-cigar"
            *ngIf="price?.NeptuneSinglePriceMin || price?.NeptuneSinglePriceMax"
          >
            <ion-icon src="assets/images/cigar.svg"></ion-icon>
            <span>{{
              [price?.NeptuneSinglePriceMin, price?.NeptuneSinglePriceMax]
                | priceRange
            }}</span>
          </span>
          <span
            class="per-humidor"
            *ngIf="price?.NeptuneBoxPriceMin || price?.NeptuneBoxPriceMax"
          >
            <ion-icon src="assets/images/my-humidors.svg"></ion-icon>
            <span>{{
              [price?.NeptuneBoxPriceMin, price?.NeptuneBoxPriceMax]
                | priceRange
            }}</span>
          </span>
        </div>

        <div class="separator"></div>

        <div class="availability">
          <span class="stock">In Stock</span>
          <span class="hyphen">-</span>
          <span class="shipping">{{ shipping }}</span>
        </div>
      </div>
    </div>
  `,
    styleUrls: ['cigar-neptune-price.scss'],
})
export class CigarNeptunePriceComponent implements AfterViewInit, OnChanges {
    @Input() price: INeptunePrices;
    @Input() aux: IAux;
    @Input() link: string;
    @Input() id: number;
    display = false;
    height: number;
    width: number;
    fontSize: number;
    shipping: string;
    @Output() cigarClicked = new EventEmitter<void>();
    @HostListener('window:resize', ['$event'])
    onResize() {
        this.resize();
    }

    constructor(
        private el: ElementRef,
        private analyticsService: AnalyticsService,
        private platform: Platform
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        // if native, do not display this component to pass store reviews
        if (this.platform.is('capacitor')) {
            return;
        }
        if (changes.aux && changes.aux.currentValue) {
            const aux = changes.aux.currentValue as IAux;
            if (aux.Availabilities && Array.isArray(aux.Availabilities)) {
                // we display this component only if one of the AvailablePcs is > 0
                this.display =
                    aux.Availabilities.filter(
                        (item) => item.AvailablePcs && item.AvailablePcs > 0
                    ).length > 0;
            }
            const days = differenceInDays(new Date(), new Date(aux.ShippingDate));
            switch (days) {
                case 0:
                    this.shipping = 'Ships Today';
                    break;
                case 1:
                    this.shipping = 'Ships Tomorrow';
                    break;
                default:
                    this.shipping = `Ships ${format(
                        new Date(aux.ShippingDate),
                        'MM/dd'
                    )}`;
                    break;
            }
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.resize(), 0);
    }

    resize() {
        this.width = this.el.nativeElement.offsetWidth;
        this.height = (159 * this.width) / 1002;
        this.fontSize = this.height / 4.5;
    }

    open() {
        if (!this.link) return;
        this.analyticsService.adsClick('NeptuneAds', this.id);
        // we do not want to open this in app but use the default browser
        window.open(`${this.link}?src=csad`, '_blank', 'location=yes');
    }
}
