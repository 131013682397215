import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router, RouterModule } from '@angular/router';
import { TagListComponent } from '@components/tag-list.component';
import { TextExpandComponent } from '@components/text-expand.component';
import {
  ActionSheetController,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRange,
  IonSkeletonText,
  IonTitle,
  IonToolbar,
  Platform,
  PopoverController,
} from '@ionic/angular/standalone';
import { CigarAttributeModal } from '@modals/cigar-attribute.component';
import { CigarLogInfoModal } from '@modals/cigar-log-info-modal/cigar-log-info';
import { ImgModalDirective } from '@modals/img-modal/img-modal.directive';
import { MyRatingModal } from '@modals/my-rating-modal/my-rating';
import ProductPostsModal from '@modals/product-posts-modal.component';
import { CigarReviewsModal } from '@modals/reviews-modal/cigar-reviews';
import {
  ICigar,
  ICigarLineShape,
  ICigarLog,
  ICigarLogListType,
  IState,
} from '@models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { ModalService } from '@services/modal.service';
import { ToastService } from '@services/toast.service';
import { UserService } from '@services/user.service';
import { CigarBrandLogoComponent } from '@shared/components/cigar-brand-logo/cigar-brand-logo.component';
import { CigarNeptunePriceComponent } from '@shared/components/cigar-neptune-price/cigar-neptune-price.component';
import { CigarPriceComponent } from '@shared/components/cigar-price/cigar-price.component';
import { RatingStarsReadOnlyComponent } from '@shared/components/rating-stars/rating-stars-readonly.component';
import { RatingStarsComponent } from '@shared/components/rating-stars/rating-stars.component';
import {
  ProductAttribute,
  ProductAttributeToDisplay,
} from '@shared/models/product-attribute.enum';
import { ShareCigarModel } from '@shared/models/share-cigar.model';
import { CigarSizePipe } from '@shared/pipes/cigar-size.pipe';
import { CreateSourceStandalonePipe } from '@shared/pipes/create-source2.pipe';
import { NlToBrPipe } from '@shared/pipes/nl-to-br.pipe';
import { CanonicalUrlService } from '@shared/services/canonical-url.service';
import { CigarListActionsService } from '@shared/services/cigar-list-actions.service';
import { ImageSourceService } from '@shared/services/image-source.service';
import { ShareService } from '@shared/services/share.service';
import * as CigarsActions from '@store/actions/cigars';
import * as myCigarsActions from '@store/actions/my-cigars';
import * as socialActions from '@store/actions/social';
import { selectOneCigar } from '@store/selectors/cigars.selectors';
import { selectOneLog } from '@store/selectors/my-cigars.selectors';
import {
  getCigarCreatedId,
  getCigarIdAndTypeCreatedId,
  isICigarLite,
} from '@utils/cigar';
import { isListHumidor } from '@utils/cigarlogs';
import { AppRoutes } from '@utils/routes';
import debug from 'debug';
import { addIcons } from 'ionicons';
import {
  add,
  addOutline,
  create,
  shareSocialOutline,
  trashOutline,
} from 'ionicons/icons';
import { combineLatest, of, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { MyNoteModal } from '../../modals/my-note-modal/my-note';
import { RatingSummaryPipe } from './rating-summary-width.pipe';

declare var window: any;

const log = debug('CigarDetailsPage');
@UntilDestroy()
@Component({
  selector: 'cigar-details',
  standalone: true,
  imports: [
    CommonModule,
    RatingStarsComponent,
    RatingStarsReadOnlyComponent,
    MyRatingModal,
    MyNoteModal,
    ImgModalDirective,
    CigarLogInfoModal,
    CigarAttributeModal,
    CigarReviewsModal,
    NlToBrPipe,
    RouterModule,
    CreateSourceStandalonePipe,
    CigarPriceComponent,
    FormsModule,
    CigarNeptunePriceComponent,
    CigarBrandLogoComponent,
    RatingSummaryPipe,
    CigarSizePipe,
    TagListComponent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonSkeletonText,
    IonButton,
    IonIcon,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonListHeader,
    IonRange,
    TextExpandComponent,
  ],
  templateUrl: 'cigar-details.html',
  styleUrls: ['cigar-details.scss'],
})
export class CigarDetailsPage implements OnInit {
  public routes = AppRoutes;
  backgroundImageUrl = '';
  userRatedProduct = false;
  biggestWidthImage = 0;
  imageAspectRatio: number = 1;
  recordId: number;
  reportSent = false;
  smokingTime: number;
  ProductAttribute = ProductAttribute;
  ProductAttributes = Object.keys(ProductAttributeToDisplay).map((key) => ({
    key,
    value: ProductAttributeToDisplay[key],
  }));
  @ViewChild('cigarShapeWrapper') cigarShapeWrapper: ElementRef;

  cigarLog: ICigarLog<ICigar> = null;
  cigar: ICigar;
  dataSubscription: Subscription;
  currentList?: ICigarLogListType;
  defaultHref?: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cigarListActions: CigarListActionsService,
    private canonicalUrlService: CanonicalUrlService,
    private imageSourceService: ImageSourceService,
    private shareService: ShareService,
    public actionSheetCtrl: ActionSheetController,
    private titleService: Title,
    private store: Store<IState>,
    public modalService: ModalService,
    private platform: Platform,
    public popoverController: PopoverController,
    private toastService: ToastService,
    private userService: UserService
  ) {
    this.recordId = this.route.snapshot.queryParams['recordId'];
    this.currentList = this.route.snapshot.params['List'];
    this.defaultHref = isListHumidor(this.currentList)
      ? AppRoutes.myHumidor(this.currentList)
      : AppRoutes.myCigars();
    addIcons({ addOutline, trashOutline, shareSocialOutline, create, add });
  }

  fetchData(params: Params) {
    const { List, CigarLogId, CigarCreatedId } = params as {
      List?: string;
      CigarLogId?: string;
      CigarCreatedId: string;
    };

    // when watching a shape e.g: tabs/my-cigars/journal/1545153/shape/P-134601
    // we do not want the cigarlog of the parent line to be interfering
    // for instance the remove button should not be displayed.
    // without that test it would be displayed
    const cigarLog$ = CigarCreatedId
      ? of(null)
      : this.store.pipe(select((state) => selectOneLog(state, CigarLogId)));

    const cigar$ = this.store.pipe(
      select((state) => selectOneCigar(state, CigarCreatedId))
    );

    cigar$.pipe(first()).subscribe((cigar) => {
      if (!CigarCreatedId) return;

      const { type, id } = getCigarIdAndTypeCreatedId(CigarCreatedId);

      return (
        !cigar &&
        this.store.dispatch(
          CigarsActions.CigarRequest({
            cigarType: type,
            id,
          })
        )
      );
    });

    cigarLog$.pipe(first()).subscribe(
      (cigarLog) =>
        (!cigarLog || isICigarLite(cigarLog.CigarDetails)) &&
        (CigarLogId || List) &&
        this.store.dispatch(
          myCigarsActions.MyCigarsGetOneRequestAction({
            listType: List,
            cigarLogId: parseInt(CigarLogId, 10),
          })
        )
    );

    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    this.dataSubscription = combineLatest([cigarLog$, cigar$]).subscribe(
      ([cigarLog, cigar]: [ICigarLog<ICigar>, ICigar]) => {
        if (!cigar && !cigarLog) {
          return;
        }

        log('cigarLog', cigarLog);
        log('cigar', cigar);

        this.cigarLog = cigarLog;
        // the cigar always takes precedence over the cigarLog
        this.cigar = cigar || cigarLog.CigarDetails;

        this.applyCanonical();
        this.smokingTime = this.cigar.MyCigarFeatures
          ? this.cigar.MyCigarFeatures.SmokingTime
          : null;

        this.setBackgroundImageUrl();
        this.titleService.setTitle(this.cigar.Name);

        this.userRatedProduct =
          !!this.cigar.MyRating && this.cigar.MyRating.Rating > 0;
        // if (this.cigar.MyRating) {
        //   this.cigar.MyRating.ProductId = this.cigar.ProductId;
        //   this.cigar.MyRating.LineId = this.cigar.LineId;
        // } else {
        //   setTimeout(() => {
        //     this.cigar.MyRating = new ProductReviewModel({
        //       ProductId: this.cigar.ProductId,
        //       LineId: this.cigar.LineId,
        //       Rating: 0,
        //     });
        //   });
        // }

        this.setImageRatio(this.cigar.Shapes);
      }
    );
  }

  ionViewWillEnter() {
    const { params } = this.route.snapshot;
    log('ionViewWillEnter', params);
  }

  ngOnInit() {
    log('ngOnInit');
    this.route.params
      .pipe(untilDestroyed(this))
      .subscribe(this.fetchData.bind(this));
  }

  ionViewWillLeave() {
    log('ionViewWillLeave');
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  applyCanonical() {
    if (this.cigar?.CanonicalUrl) {
      this.canonicalUrlService.insert(this.cigar.CanonicalUrl);
    }
  }

  async addTo() {
    if (!this.cigar) return;
    const isLog =
      this.route.snapshot.params['CigarLogId'] !== undefined &&
      this.cigarLog !== null;
    const isShapeOrCigar = this.route.snapshot.params['CigarCreatedId'];
    const shouldCopy = isLog && !isShapeOrCigar;
    this.cigarListActions.addTo(
      shouldCopy ? this.cigarLog : this.cigar,
      this.route.snapshot.params['CigarCreatedId'] ? null : this.currentList
    );
    // const actionSheet = await this.actionSheetCtrl.create({
    //   buttons: [
    //     {
    //       text: 'Add to Lists',
    //       handler: () => {
    //         this.cigarListActions.addToLists(
    //           shouldCopy ? this.cigarLog : this.cigar,
    //           this.route.snapshot.params['CigarCreatedId']
    //             ? null
    //             : this.currentList
    //         );
    //       },
    //     },
    //     {
    //       text: 'Add to Humidors',
    //       handler: () => {
    //         this.cigarListActions.addToHumidors(
    //           shouldCopy ? this.cigarLog : this.cigar,
    //           this.route.snapshot.params['CigarCreatedId']
    //             ? null
    //             : this.currentList
    //         );
    //       },
    //     },

    //     {
    //       text: 'Cancel',
    //       role: 'cancel',
    //     },
    //   ],
    // });

    // await actionSheet.present();
  }

  remove() {
    this.cigarListActions.remove(this.cigarLog);
  }

  /**
   * @todo: use a modal instead of the router
   */
  editCustomCigar() {
    this.router.navigate(['custom-cigar', 'edit'], {
      queryParams: {
        CigarLogId: this.cigarLog.Id,
      },
    });
  }

  reportBadResult() {
    this.cigarListActions.reportBadResult(this.recordId).subscribe((res) => {
      this.reportSent = true;
      this.toastService.show('Report has been sent');
    });
  }

  shareOptions() {
    const buttons: any[] = [
      {
        text: 'Share With Cigar Scanner Users',
        handler: async () => {
          const shareCigarData = {} as ShareCigarModel;
          shareCigarData.Title = `Share On Cigar Scanner ${this.cigar.Name}`;
          shareCigarData.ImageUrl = this.backgroundImageUrl;
          shareCigarData.Location =
            await this.userService.getLocationIfAllowed();

          if (this.cigar.ProductId) {
            shareCigarData.ProductId = this.cigar.ProductId;
          } else {
            shareCigarData.LineId = this.cigar.LineId;
          }

          this.shareCigarOnSocialFeed(shareCigarData);
        },
      },
    ];

    // Web Share is not everywhere
    if (
      'share' in navigator ||
      (this.platform.is('capacitor') && this.platform.is('android'))
    ) {
      buttons.push({
        text: 'Other Sharing options',
        handler: () => {
          this.shareService.shareProduct(this.cigar);
        },
      });
    }
    buttons.push({
      text: 'Cancel',
      role: 'cancel',
    });

    this.actionSheetCtrl
      .create({
        header: 'Choose sharing options',
        buttons,
      })
      .then((actionSheet) => actionSheet.present());
  }

  async shareCigarOnSocialFeed(shareCigarData: ShareCigarModel) {
    this.store.dispatch(
      socialActions.SocialPostCreateRequest({
        post: shareCigarData,
      })
    );
  }

  async openAttributeModal(attributeName: string) {
    const modal = await this.modalService.createCSModal(
      CigarAttributeModal,
      {
        // import the new models while this page uses the old one
        cigar: this.cigar,
        attributeName,
      },
      {
        mobile: {
          initialBreakpoint: 0.5,
          breakpoints: [0, 0.5, 1],
        },
      }
    );
    return await modal.present();
  }
  async openCigarPriceAlert(e?: number) {
    this.cigarListActions.openPriceAlert(this.cigarLog);
  }

  async openCigarReviewModal(e?: number) {
    this.cigarListActions.openReviewModal(this.cigar, e);
  }

  async openCigarLogInfoModal() {
    // this.router.navigate(['cigar-log-info'], { relativeTo: this.route });
    const modal = await this.modalService.createCSModal(CigarLogInfoModal, {
      // import the new models while this page uses the old one
      cigar: this.cigar,
      cigarLog: this.cigarLog.Id ? this.cigarLog : undefined,
    });
    return await modal.present();
  }

  async openReviewsModal(rating: number) {
    const modal = await this.modalService.createCSModal(CigarReviewsModal, {
      // import the new models while this page uses the old one
      cigar: this.cigar,
      rating,
    });
    return await modal.present();
  }

  setBackgroundImageUrl() {
    if (this.cigarLog && this.cigarLog.UserImageUrl) {
      this.backgroundImageUrl = this.cigarLog.UserImageUrl;
    } else if (this.cigar && this.cigar.UserImageUrl) {
      this.backgroundImageUrl = this.cigar.UserImageUrl;
    } else if (this.cigar && this.cigar.ImageUrl) {
      this.backgroundImageUrl = this.cigar.ImageUrl;
    } else {
      this.backgroundImageUrl = null;
      return null;
    }

    return `url(${this.imageSourceService.createSrc(this.backgroundImageUrl)})`;
  }

  setImageRatio(shapes = []) {
    if (
      !shapes.length ||
      !this.cigarShapeWrapper ||
      this.cigarShapeWrapper.nativeElement.offsetWidth === 0
    ) {
      return;
    }
    // let holdingContainer;
    for (let i = 0; i < shapes.length; i++) {
      const shape = shapes[i];
      const shapeWidth = shape.ImageOfSingleWidth;
      const shapeheight = shape.ImageOfSingleHeight;
      // 2.5 ratio is the minimum to be considered a cigar
      if (!shapeWidth || !shapeheight || shapeWidth / shapeheight < 2.5)
        continue;
      if (shapeWidth > this.biggestWidthImage) {
        this.biggestWidthImage = shapeWidth;
      }
    }
    // 20 being the item slot end link icon
    // 15 being the margin
    const maxScreenWidth =
      this.cigarShapeWrapper.nativeElement.offsetWidth - 20 - 15;
    if (maxScreenWidth < this.biggestWidthImage) {
      this.imageAspectRatio = +(
        maxScreenWidth / this.biggestWidthImage
      ).toFixed(4);
    }
  }

  getCigarImageWidth(shape: ICigarLineShape) {
    return shape.ImageOfSingleWidth * this.imageAspectRatio;
  }

  async showProductPosts() {
    const modal = await this.modalService.createCSModal(ProductPostsModal, {
      productId: getCigarCreatedId(this.cigar),
      title: this.cigar.Name,
    });
    return await modal.present();
  }

  openSmokingTimeModal() {
    this.cigarListActions.smokingTimeAlert(this.cigar);
  }

  async openNoteModal() {
    this.cigarListActions.openNoteModal(this.cigar);
  }
}
