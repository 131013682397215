import { Injectable } from '@angular/core';
import { ICigarLog, ICigarLogActionProps, IState } from '@models';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as myCigarsActions from '@store/actions/my-cigars';
import { finalize, first } from 'rxjs/operators';

const noop = () => {};

@Injectable({ providedIn: 'root' })
export class MyCigarsStoreService {
  constructor(private actions$: Actions, protected store: Store<IState>) {}

  updateLog(
    options: {
      cigarLog: ICigarLog;
    },
    onFinished: () => void = noop,
    onSuccess: (
      actions: ReturnType<typeof myCigarsActions.MyCigarsUpdateSuccessAction>
    ) => void = noop,
    onError: (
      actions: ReturnType<typeof myCigarsActions.MyCigarsErrorAction>
    ) => void = noop
  ) {
    this.store.dispatch(myCigarsActions.MyCigarsUpdateRequestAction(options));

    this.actions$
      .pipe(
        ofType<
          | typeof myCigarsActions.MyCigarsUpdateSuccessAction
          | typeof myCigarsActions.MyCigarsErrorAction
        >(
          myCigarsActions.MyCigarsUpdateSuccessAction,
          myCigarsActions.MyCigarsErrorAction
        ),
        first(),
        finalize(onFinished)
      )
      .subscribe((action) => {
        if (isMyCigarsUpdateSuccessAction(action)) {
          // @ts-ignore
          onSuccess(action);
        } else {
          // @ts-ignore
          onError(action);
        }
      });
  }
}

function isMyCigarsCreateSuccessAction(
  action:
    | typeof myCigarsActions.MyCigarsCreateSuccessAction
    | typeof myCigarsActions.MyCigarsErrorAction
): action is typeof myCigarsActions.MyCigarsCreateSuccessAction {
  // @ts-ignore
  return action.cigarLog !== undefined;
}

function isMyCigarsUpdateSuccessAction(
  action:
    | typeof myCigarsActions.MyCigarsUpdateSuccessAction
    | typeof myCigarsActions.MyCigarsErrorAction
): action is typeof myCigarsActions.MyCigarsUpdateSuccessAction {
  // @ts-ignore
  return action.cigarLog !== undefined;
}
