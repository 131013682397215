import { Pipe, PipeTransform } from '@angular/core';
import { IRatingSummary } from '@models';

@Pipe({
  name: 'ratingSummaryRatio',
  standalone: true,
})
export class RatingSummaryPipe implements PipeTransform {
  transform(summary: IRatingSummary, key: string): any {
    if (!summary || !summary[key] || typeof summary.RatingCount !== 'number') {
      return 0;
    }
    return (summary[key] / summary.RatingCount) * 100;
  }
}
