import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ICigar, ICigarReview } from '@models';
import { CigarsService } from '@services/cigars.service';
import { ModalService } from '@services/modal.service';
import { RatingStarsReadOnlyComponent } from '@shared/components/rating-stars/rating-stars-readonly.component';
import { NlToBrPipe } from '@shared/pipes/nl-to-br.pipe';
import { finalize } from 'rxjs/operators';
import { addIcons } from "ionicons";
import { closeOutline } from "ionicons/icons";
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonSegment, IonSegmentButton, IonContent, IonRefresher, IonRefresherContent, IonProgressBar, IonList, IonItem, IonLabel, IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/angular/standalone";

const DEFAULT_TAKE = 25;

@Component({
    standalone: true,
    imports: [
        CommonModule,
        NlToBrPipe,
        RatingStarsReadOnlyComponent,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonButtons,
        IonButton,
        IonIcon,
        IonSegment,
        IonSegmentButton,
        IonContent,
        IonRefresher,
        IonRefresherContent,
        IonProgressBar,
        IonList,
        IonItem,
        IonLabel,
        IonInfiniteScroll,
        IonInfiniteScrollContent
    ],
    templateUrl: 'cigar-reviews.html',
})
export class CigarReviewsModal implements OnInit {
    @Input() cigar: ICigar;
    @Input() rating: number;
    isLoading = false;
    reviews: ICigarReview[] = [];
    total = 0;
    withCommentOnly = true;

    constructor(
        private cigarService: CigarsService,
        private modalService: ModalService
    ) {
        addIcons({ closeOutline });
    }

    ngOnInit() {
        this.getReviews(this.withCommentOnly, this.reviews.length);
    }

    getReviews(withCommentOnly, skip, reset = true, onFinished = () => { }) {
        this.isLoading = true;
        this.cigarService
            .getReviews(this.cigar, this.rating, withCommentOnly, DEFAULT_TAKE, skip)
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                    onFinished();
                })
            )
            .subscribe((res) => {
                if (reset) {
                    this.reviews = res;
                } else {
                    this.reviews = this.reviews.concat(res);
                }
                this.total = DEFAULT_TAKE + skip;
            });
    }

    toggleReviewsToDisplay(withCommentOnly: boolean) {
        this.withCommentOnly = withCommentOnly;
        this.getReviews(this.withCommentOnly, 0);
    }

    doRefresh(event) {
        this.getReviews(this.withCommentOnly, 0, true, () =>
            event.target.complete()
        );
    }

    nextPage(event) {
        this.getReviews(this.withCommentOnly, this.total, false, () =>
            event.target.complete()
        );
    }

    dismiss() {
        this.modalService.dismiss();
    }
}
