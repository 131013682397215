import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ICigar } from '@models';
import { ModalService } from '@services/modal.service';
import { NlToBrPipe } from '@shared/pipes/nl-to-br.pipe';
import { addIcons } from "ionicons";
import { close } from "ionicons/icons";
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent } from "@ionic/angular/standalone";

@Component({
    standalone: true,
    imports: [CommonModule, NlToBrPipe, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent],
    template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ attributeName }} - {{ attribute }}</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()" color="dark">
            <ion-icon name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <div class="prose dark:prose-invert m-auto">
        <p [innerHTML]="attributeDescription | nl2br"></p>
      </div>
    </ion-content>
  `,
})
export class CigarAttributeModal implements OnInit {
    @Input() cigar: ICigar;
    @Input() attributeName = '';
    attribute = '';
    attributeDescription = '';

    constructor(private modalService: ModalService) {
        addIcons({ close });
    }

    ngOnInit() {
        this.attribute = this.cigar.Attributes[this.attributeName];
        this.attributeDescription =
            this.cigar.Attributes[this.attributeName + 'Description'];
    }

    dismiss() {
        this.modalService.dismiss();
    }
}
