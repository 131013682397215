import { closeOutline } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  InfiniteScrollCustomEvent,
  IonAvatar,
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  RefresherCustomEvent,
} from '@ionic/angular/standalone';
import { IState } from '@models';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { RxFor } from '@rx-angular/template/for';
import { RxLet } from '@rx-angular/template/let';
import { ModalService } from '@services/modal.service';
import { SocialPostContainer } from '@shared/components/social-post/social-post.container';
import { SocialPostSkeleton } from '@shared/components/social-post/social-post.skeleton';
import { SocialPostModel } from '@shared/models/social-post.model';
import { CreateSourceStandalonePipe } from '@shared/pipes/create-source2.pipe';
import { UserNamePipe } from '@shared/pipes/user-name.pipe';
import * as socialActions from '@store/actions/social';
import { getSocialProductCollectionId } from '@store/reducers/social';
import { getSocialSelectors } from '@store/selectors/social.selectors';
import { Observable, Subscription, filter, first, mergeMap } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    CreateSourceStandalonePipe,
    RxFor,
    RxLet,
    UserNamePipe,
    SocialPostContainer,
    SocialPostSkeleton,
    IonHeader,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonBackButton,
    IonItem,
    IonAvatar,
    IonLabel,
    IonBadge,
    IonProgressBar,
    IonContent,
    IonList,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
    IonTitle,
  ],
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ title }}</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()" color="dark">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-progress-bar
          type="indeterminate"
          *ngIf="isLoading$ | async"
        ></ion-progress-bar>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
        <ion-refresher-content> </ion-refresher-content>
      </ion-refresher>
      <ng-container *rxLet="posts$; let posts">
        <ng-container *rxLet="isLoading$; let isLoading">
          <ion-item
            lines="full"
            color="light"
            *ngIf="!isLoading && !posts?.length"
          >
            <ion-label>no post yet</ion-label>
          </ion-item>
          <cs-social-post-skeleton
            *ngIf="isLoading && !posts?.length"
          ></cs-social-post-skeleton>
        </ng-container>
        <cs-social-post-container
          *rxFor="let post of posts; trackBy: 'Id'"
          [post]="post"
          [hideCigar]="true"
          (navigateToProductClicked)="navigateToProduct(post)"
        >
        </cs-social-post-container>
      </ng-container>
      <ion-infinite-scroll
        [disabled]="(showMore$ | async) === false"
        threshold="100px"
        (ionInfinite)="loadMore($event)"
      >
        <ion-infinite-scroll-content> </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class ProductPostsModal implements OnInit, OnDestroy {
  @Input() title: string = '';
  @Input() productId: string = '';
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  posts$: Observable<SocialPostModel[]>;
  isLoading$: Observable<boolean>;
  showMore$: Observable<boolean>;
  routerSub: Subscription;

  constructor(
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private store: Store<IState>,
    private readonly actions$: Actions
  ) {
    addIcons({ closeOutline });
  }

  ngOnInit() {
    this.routerSub = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.dismiss();
      });
    this.posts$ = this.store.pipe(
      select(
        getSocialSelectors(getSocialProductCollectionId(this.productId))
          .selectAll
      )
    );
    this.isLoading$ = this.store.pipe(
      select(
        getSocialSelectors(getSocialProductCollectionId(this.productId))
          .isLoading
      )
    );
    this.showMore$ = this.store.pipe(
      select(
        getSocialSelectors(getSocialProductCollectionId(this.productId))
          .showMore
      )
    );
    this.init();
  }

  ngOnDestroy(): void {
    this.routerSub.unsubscribe();
    // destroy feed
    this.store.dispatch(
      socialActions.SocialPostsCleanup({
        collectionId: getSocialProductCollectionId(this.productId),
      })
    );
  }

  dismiss() {
    this.modalService.dismiss();
  }

  ngAfterViewInit() {}

  ionViewDidEnter() {}

  init() {
    this.store.dispatch(
      socialActions.SocialProductPostsRequest({
        productId: this.productId,
        skip: 0,
      })
    );
  }

  doRefresh(event: RefresherCustomEvent) {
    this.init();
    const pageCollectionId = getSocialProductCollectionId(this.productId);

    this.actions$
      .pipe(
        ofType(
          socialActions.SocialPostsSuccess,
          socialActions.SocialPostsError
        ),
        first()
      )
      .subscribe(({ collectionId }) => {
        if (collectionId === pageCollectionId) {
          event.target.complete();
        }
      });
  }

  loadMore(event: InfiniteScrollCustomEvent) {
    const pageCollectionId = getSocialProductCollectionId(this.productId);

    this.actions$
      .pipe(
        ofType(
          socialActions.SocialPostsSuccess,
          socialActions.SocialPostsError
        ),
        first()
      )
      .subscribe(({ collectionId }) => {
        if (collectionId === pageCollectionId) {
          event.target.complete();
        }
      });
    this.store.dispatch(
      socialActions.SocialProductPostsRequest({
        productId: this.productId as string,
      })
    );
  }

  navigateToProduct(post: SocialPostModel) {
    if (!post.ProductId && !post.LineId) {
      return;
    }

    this.router.navigate([
      'tabs',
      'social',
      'cigar',
      post.ProductId ? `P-${post.ProductId}` : `L-${post.LineId}`,
    ]);
  }
}
