import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ContentService } from '@services/content.service';
import { LoaderService } from '@shared/services/loader.service';
import { finalize, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentPresenterResolver {
  constructor(
    private loader: LoaderService,
    private contentService: ContentService
  ) {}

  async resolve(route: ActivatedRouteSnapshot) {
    const id = Number(route.data.articleId);
    this.loader.show();
    return firstValueFrom(
      this.contentService.get(id).pipe(finalize(() => this.loader.hide()))
    );
  }
}
