import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonProgressBar,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { LocationModal } from '@modals/location-modal.component';
import { ICigarLog } from '@models';
import { ModalService } from '@services/modal.service';
import { ToastService } from '@services/toast.service';
import { UserService } from '@services/user.service';
import { ProductModel } from '@shared/models/product.model';
import { CameraService } from '@shared/services/camera.service';
import { CigarLogInfoService } from '@shared/services/cigar-log-info.service';
import { ImageSourceService } from '@shared/services/image-source.service';
import { MyCigarsStoreService } from '@store/services/my-cigars';
import { getErrorMessage } from '@utils/errors';
import { addIcons } from 'ionicons';
import {
  closeCircle,
  closeOutline,
  imageOutline,
  locationSharp,
} from 'ionicons/icons';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'cigar-log-info',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonProgressBar,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonDatetimeButton,
    IonModal,
    IonDatetime,
    IonFooter,
    IonChip,
  ],
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>Edit Info</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()"
            ><ion-icon slot="icon-only" name="close-outline"></ion-icon
          ></ion-button>
        </ion-buttons>
        <ion-progress-bar
          type="indeterminate"
          *ngIf="isLoading"
        ></ion-progress-bar>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <form [formGroup]="formGroup" (ngSubmit)="submit()">
        <ion-list>
          <ion-item>
            <ion-label>Date added:</ion-label>
            <ion-datetime-button datetime="datetime"></ion-datetime-button>
            <ion-modal [keepContentsMounted]="true">
              <ng-template>
                <ion-datetime
                  presentation="date"
                  id="datetime"
                  formControlName="Date"
                  [showDefaultButtons]="true"
                ></ion-datetime>
              </ng-template>
            </ion-modal>
          </ion-item>
          <ion-chip
            class="mt-2"
            [outline]="true"
            color="primary"
            (click)="openLocationModal()"
            [disabled]="formGroup.get('Location').disabled"
            *ngIf="formGroup.get('Location').value"
          >
            <ion-icon name="location-sharp" class="mr-1 ml-0"></ion-icon>
            {{ formGroup.get('Location').value }}
            <ion-icon name="close-circle" (click)="removeLocation()"></ion-icon>
          </ion-chip>
          <ion-item
            lines="none"
            class="cigar-preview"
            [ngStyle]="{
              'background-image': getBackgroundImageUrl(),
              'background-color': backgroundImageUrl ? '#000' : '',
              'background-size': backgroundImageUrl ? '150%' : '',
              height: setBackgroundImageHeight()
            }"
          >
          </ion-item>
        </ion-list>
        <div class="text-center">
          <ion-button
            [disabled]="isLoading"
            fill="outline"
            color="primary"
            (click)="takePicture()"
          >
            <ion-icon slot="start" name="image-outline"></ion-icon>
            <span *ngIf="backgroundImageUrl">Update Cigar Picture</span>
            <span *ngIf="!backgroundImageUrl">Add Cigar Picture</span>
          </ion-button>
        </div>
      </form>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-button
          slot="start"
          (click)="openLocationModal()"
          size="large"
          [disabled]="formGroup.get('Location').disabled"
          [color]="formGroup.get('Location').value ? 'primary' : 'medium'"
          type="button"
          fill="clear"
        >
          <ion-icon
            class="text-3xl"
            slot="icon-only"
            name="location-sharp"
          ></ion-icon>
        </ion-button>
        <ion-button
          slot="end"
          (click)="submit()"
          size="large"
          color="primary"
          type="button"
          [disabled]="isLoading || formGroup.invalid"
        >
          Save
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  `,
  styleUrls: ['cigar-log-info.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CigarLogInfoModal implements OnInit {
  @Input() cigar: ProductModel;
  @Input() cigarLog: ICigarLog;
  isLoading = false;
  backgroundImageUrl: string;
  formGroup = this.fb.group({
    Date: ['', [Validators.required]],
    Location: [''],
    UserImageUrl: [''],
  });

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private cameraService: CameraService,
    private imageSourceService: ImageSourceService,
    private myCigarsStoreService: MyCigarsStoreService,
    private modalService: ModalService,
    private toastService: ToastService,
    private cigarInfoService: CigarLogInfoService,
    private userService: UserService
  ) {
    addIcons({ closeOutline, imageOutline, locationSharp, closeCircle });
  }

  ngOnInit() {
    if (this.cigarLog) {
      let date = '';
      try {
        if (this.cigarLog.Date) {
          date = new Date(this.cigarLog.Date).toISOString();
        }
      } catch (error) {}
      this.formGroup.patchValue({
        Date: date,
        Location: this.cigarLog.Location,
        UserImageUrl: this.cigarLog.UserImageUrl,
      });
      this.cdr.markForCheck();
      if (!this.cigarLog.Location) {
        this.setLocation();
      }
    } else {
      console.error(':(');
    }
  }

  async setLocation() {
    const location = await this.userService.getLocation();
    this.formGroup.get('Location').setValue(location);
    this.cdr.markForCheck();
  }

  async takePicture() {
    try {
      const { image } = await this.cameraService.showCameraOptions();
      this.isLoading = true;
      this.cdr.markForCheck();
      const imageUrl = await firstValueFrom(
        this.cigarInfoService.getImageUrl(image.file)
      );
      this.formGroup.get('UserImageUrl').setValue(imageUrl);
      this.isLoading = false;
      this.cdr.markForCheck();
    } catch (error) {
      this.isLoading = false;
      this.cdr.markForCheck();
      await this.toastService.show(getErrorMessage(error));
    }
  }

  async openLocationModal() {
    const modal = await this.modalService.createCSModal(LocationModal, {
      location: this.formGroup.get('Location').value,
    });
    await modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'success' && data) {
      this.formGroup.get('Location').setValue(data);
      this.cdr.markForCheck();
    } else if (role === 'clear') {
      this.removeLocation();
    }
  }

  removeLocation() {
    this.formGroup.get('Location').setValue('');
    this.cdr.markForCheck();
  }

  getBackgroundImageUrl() {
    const userImageUrl = this.formGroup.get('UserImageUrl').value;
    if (userImageUrl) {
      this.backgroundImageUrl = userImageUrl;
    } else if (this.cigarLog?.UserImageUrl) {
      this.backgroundImageUrl = this.cigarLog.UserImageUrl;
    } else if (this.cigarLog?.CigarDetails?.ImageUrl) {
      this.backgroundImageUrl = this.cigarLog.CigarDetails.ImageUrl;
    } else {
      this.backgroundImageUrl = null;
      return null;
    }
    return `url(${this.imageSourceService.createSrc(this.backgroundImageUrl)})`;
  }

  setBackgroundImageHeight() {
    if (!this.backgroundImageUrl) {
      return '';
    }

    if (window.innerWidth > 1200) {
      return '300px';
    } else {
      return '200px';
    }
  }

  submit() {
    this.isLoading = true;
    this.cdr.markForCheck();
    const data = this.formGroup.value;

    // if (this.humidor) {
    //   const legacyLog = new CigarLogModel(this.cigarLog);
    //   legacyLog.Date = data.Date;
    //   legacyLog.Location = data.Location;
    //   legacyLog.Price = data.Price;
    //   legacyLog.UserImageUrl = data.UserImageUrl;

    //   this.myHumidorService
    //     .updateCigar(legacyLog)
    //     .pipe(
    //       finalize(() => {
    //         this.isLoading = false;
    //         this.cdr.markForCheck();
    //       })
    //     )
    //     .subscribe(() => this.dismiss());
    // } else {
    this.myCigarsStoreService.updateLog(
      {
        cigarLog: {
          ...this.cigarLog,
          ...data,
        },
      },
      () => {
        this.isLoading = false;
        this.cdr.markForCheck();
      },
      () => this.dismiss()
    );
    // }
  }

  dismiss() {
    this.modalService.dismiss();
  }
}
