import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import _isString from 'lodash-es/isString';
import debug from 'debug';

import { CanonicalUrlService } from '@shared/services/canonical-url.service';

const log = debug('DocumentPresenter');
@Component({
    selector: 'privacy-policy',
    template: `
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>{{ title }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div class="ion-padding" [innerHTML]="content"></div>
    </ion-content>
  `,
    styles: [
        `
      :host ::ng-deep * {
        color: var(--ion-text-color) !important;
      }
    `,
    ],
})
export class DocumentPresenter implements OnInit {
    articleId: number;
    collection = [6279, 6167, 6166, 6281];
    title = '';
    content: SafeHtml;

    constructor(
        private sanitizer: DomSanitizer,
        private activatedRoute: ActivatedRoute,
        private canonicalUrlService: CanonicalUrlService
    ) { }

    ionViewDidEnter() {
        log('ionViewDidEnter');
        const data = this.activatedRoute.snapshot.data;
        if (data.documentData && data.documentData.Url) {
            this.canonicalUrlService.insert(
                this.activatedRoute.snapshot.data.documentData.Url
            );
        }
    }

    ngOnInit() {
        const data = this.activatedRoute.snapshot.data;
        this.articleId = data.articleId;
        if (data.documentData && data.documentData.Title) {
            this.title = data.documentData.Title;
        }
        if (data.documentData && data.documentData.Html) {
            if (_isString(data.documentData.Html)) {
                this.content = this.sanitizer.bypassSecurityTrustHtml(
                    data.documentData.Html.replace(
                        /(src=")(?!(https?:)?\/\/)\/?/gi,
                        '$1https://www.neptunecigar.com/'
                    ).replace(/(src="\/\/)/gi, 'src="https://')
                );
            }
        }
    }
}
